import { WeeklyOption } from '../types/interfaces';

const API_BASE_URL = 'https://mcxxnegx7y.us-east-1.awsapprunner.com';

export const fetchWeeklyOptions = async (): Promise<WeeklyOption[]> => {
  const response = await fetch(`${API_BASE_URL}/weekly-options/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (!response.ok) {
    throw new Error('Failed to fetch weekly options');
  }
  return response.json();
};

export const updateStates = async (stateUpdate: any): Promise<WeeklyOption> => {
  const response = await fetch(`${API_BASE_URL}/update-states/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(stateUpdate),
  });
  if (!response.ok) {
    throw new Error('Failed to update states');
  }
  return response.json();
};

export const fetchWeeklyChoice = async (): Promise<WeeklyOption> => {
  const response = await fetch(`${API_BASE_URL}/weekly-choice/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (!response.ok) {
    throw new Error('Failed to fetch weekly choice');
  }
  return response.json();
};

export const updateRatings = async (ratingsUpdate: any): Promise<WeeklyOption> => {
  const response = await fetch(`${API_BASE_URL}/update-ratings/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(ratingsUpdate),
  });
  if (!response.ok) {
    throw new Error('Failed to update ratings');
  }
  return response.json();
};
