import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, Navigate } from 'react-router-dom';
import { ProfileProvider } from './context/ProfileContext';
import { VotingProvider } from './context/VotingContext';
import { WeekManagerProvider } from './context/WeekManagerContext';
import Home from './pages/Home';
import Results from './pages/Results';
import { fetchWeeklyOptions } from './utils/api';
import './App.css';
import { Movie, WeeklyOption } from './types/interfaces';
import Clock from './components/Clock/Clock';
import useCurrentPage from './hooks/useCurrentPage';

const App: React.FC = () => {
  const [movies, setMovies] = useState<Movie[]>([]);
  const [initialVotes, setInitialVotes] = useState<{ [profileId: number]: { favorite: number | null; veto: number | null } }>({});
  const [dataFetched, setDataFetched] = useState(false);
  const currentPage = useCurrentPage();
  const navigate = useNavigate();
  const [initialNavigationDone, setInitialNavigationDone] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchWeeklyOptions();
        const moviesFromData = data.map((item: WeeklyOption) => item.movie);
        setMovies(moviesFromData);

        const initialVotesData: { [profileId: number]: { favorite: number | null; veto: number | null } } = {};
        data.forEach((option: WeeklyOption) => {
          initialVotesData[option.movie_id] = {
            favorite: option.user_ds === 1 ? option.movie_id : null,
            veto: option.user_ds === 0 ? option.movie_id : null
          };
        });
        setInitialVotes(initialVotesData);
        setDataFetched(true);
      } catch (error) {
        console.error('Error loading movies and votes:', error);
      }
    };

    fetchData();
  }, []); // The empty dependency array ensures fetchData is called only once when the component mounts

  useEffect(() => {
    if (dataFetched && !initialNavigationDone) {
      if (window.location.pathname === '/') {
        navigate(currentPage, { replace: true });
      }
      setInitialNavigationDone(true);
    }
  }, [dataFetched, currentPage, initialNavigationDone, navigate]);

  return (
      <ProfileProvider>
        <VotingProvider initialVotes={initialVotes}>
          <WeekManagerProvider>
            <Clock />
            <div className="app">
              <div className="main-section">
                {dataFetched && (
                    <Routes>
                      <Route path="/voting" element={<Home movies={movies} />} />
                      <Route path="/results" element={<Results />} />
                      <Route path="/" element={<Navigate to={currentPage} replace />} />
                      <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                )}
              </div>
            </div>
          </WeekManagerProvider>
        </VotingProvider>
      </ProfileProvider>
  );
};

const WrappedApp: React.FC = () => {
  return (
      <Router>
        <App />
      </Router>
  );
};

export default WrappedApp;
