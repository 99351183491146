import React, { useEffect, useState } from 'react';
import SandClock from './SandClock';

interface TimerProps {
    duration: number;
    onTimeUp: () => void;
}

const Timer: React.FC<TimerProps> = ({ duration, onTimeUp }) => {
    const [timeLeft, setTimeLeft] = useState(duration);

    useEffect(() => {
        if (timeLeft <= 0) {
            onTimeUp();
            return;
        }

        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [timeLeft, onTimeUp]);

    return (
        <div>
            <SandClock />
            <div>{Math.floor(timeLeft / 60)}:{String(timeLeft % 60).padStart(2, '0')} minutes left</div>
        </div>
    );
};

export default Timer;
