import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

interface WeekManagerContextType {
  weekNumber: number;
  choicesLocked: boolean;
  profileLocks: { [profileId: number]: number };
  startInteraction: (profileId: number) => void;
  checkProfileLock: (profileId: number) => boolean;
  timerDuration: number;
  isTimerRunning: boolean;
  startTimer: () => void;
}

const WeekManagerContext = createContext<WeekManagerContextType | undefined>(undefined);

export const WeekManagerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [weekNumber, setWeekNumber] = useState<number>(getWeekNumber(new Date()));
  const [choicesLocked, setChoicesLocked] = useState<boolean>(false);
  const [profileLocks, setProfileLocks] = useState<{ [profileId: number]: number }>({});
  const [timerDuration] = useState<number>(5); // 5 minutes, you can change this value for different duration
  const [isTimerRunning, setIsTimerRunning] = useState<boolean>(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const currentWeekNumber = getWeekNumber(now);
      setWeekNumber(currentWeekNumber);

      const lockTime = new Date();
      lockTime.setHours(20, 0, 0, 0); // Sunday 8 PM

      if (now > lockTime && now.getDay() === 0) {
        setChoicesLocked(true);
      } else {
        setChoicesLocked(false);
      }
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const startInteraction = (profileId: number) => {
    if (!profileLocks[profileId]) {
      const now = new Date();
      const lockTime = new Date(now.getTime() + timerDuration * 60000); // timerDuration minutes later
      setProfileLocks((prev) => ({ ...prev, [profileId]: lockTime.getTime() }));
    }
  };

  const checkProfileLock = (profileId: number): boolean => {
    const now = new Date().getTime();
    return profileLocks[profileId] !== undefined && now > profileLocks[profileId];
  };

  const startTimer = () => {
    setIsTimerRunning(true);
  };

  return (
    <WeekManagerContext.Provider value={{ weekNumber, choicesLocked, profileLocks, startInteraction, checkProfileLock, timerDuration, isTimerRunning, startTimer }}>
      {children}
    </WeekManagerContext.Provider>
  );
};

export const useWeekManager = () => {
  const context = useContext(WeekManagerContext);
  if (context === undefined) {
    throw new Error('useWeekManager must be used within a WeekManagerProvider');
  }
  return context;
};

const getWeekNumber = (date: Date): number => {
  const start = new Date(date.getFullYear(), 0, 1);
  const diff = (date.getTime() - start.getTime() + (start.getTimezoneOffset() - date.getTimezoneOffset()) * 60000) / 86400000;
  return Math.floor((diff + start.getDay() + 1) / 7);
};
