import React from 'react';
import ProfileCircle from '../ProfileCircle/ProfileCircle';
import { Profile } from '../../context/ProfileContext';
import styles from './ProfileSection.module.css';

interface ProfileSectionProps {
  profiles: Profile[];
  handleProfileClick: (profileId: number) => void;
  activeProfileId: number | null; // Add activeProfileId prop
}

const ProfileSection: React.FC<ProfileSectionProps> = ({ profiles, handleProfileClick, activeProfileId }) => {
  return (
      <div className={styles.profileSection}>
        {profiles.map(profile => (
            <div key={profile.id} className={styles.profileContainer}>
              <ProfileCircle
                  id={profile.id}
                  name={profile.name}
                  imageUrl={profile.imageUrl}
                  onClick={() => handleProfileClick(profile.id)}
                  isSelected={profile.id === activeProfileId} // Pass isSelected prop
              />
              <span className={styles.profileName}>{profile.name}</span>
            </div>
        ))}
      </div>
  );
};

export default ProfileSection;
