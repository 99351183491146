import React from 'react';
import Card from '../Card/Card';
import { Movie } from '../../utils/csvParser';
import styles from './CardSection.module.css';

interface CardSectionProps {
  movie: Movie;
  isFavorite: boolean;
  isVetoed: boolean;
  handleFavorite: (id: number) => void;
  handleVeto: (id: number) => void;
}

const CardSection: React.FC<CardSectionProps> = ({ movie, isFavorite, isVetoed, handleFavorite, handleVeto }) => {
  return (
    <div className={styles.cardSection}>
      <Card
        id={movie.id}
        title={movie.title}
        description={movie.summary}
        isFavorite={isFavorite}
        isVetoed={isVetoed}
        onFavorite={handleFavorite}
        onVeto={handleVeto}
      />
    </div>
  );
};

export default CardSection;
