import React, { useState, useEffect } from 'react';
import { useProfile } from '../context/ProfileContext';
import { fetchWeeklyChoice, updateStates } from '../utils/api';
import { WeeklyOption } from '../types/interfaces';
import styles from './Results.module.css';

const emojis = ['❌', '😢', '😐', '😊'];

const Results: React.FC = () => {
  const { profiles } = useProfile();
  const [weeklyChoice, setWeeklyChoice] = useState<WeeklyOption | null>(null);
  const [watchedStates, setWatchedStates] = useState<{ [key: string]: number }>({
    user_ds: 0,
    user_es: 0,
    user_dh: 0,
    user_tk: 0,
  });
  const [isTeaseVisible, setIsTeaseVisible] = useState<boolean>(false);

  useEffect(() => {
    fetchWeeklyChoice().then((data) => {
      setWeeklyChoice(data);
      setWatchedStates({
        user_ds: data.user_ds,
        user_es: data.user_es,
        user_dh: data.user_dh,
        user_tk: data.user_tk,
      });
    }).catch(error => console.error('Error fetching weekly choice:', error));
  }, []);

  const handleRatingChange = (user: string, rating: number) => {
    const updatedWatchedStates = {
      ...watchedStates,
      [user]: rating,
    };
    setWatchedStates(updatedWatchedStates);

    const payload = {
      movie_id: weeklyChoice?.movie_id,
      user_ds: updatedWatchedStates.user_ds,
      user_es: updatedWatchedStates.user_es,
      user_dh: updatedWatchedStates.user_dh,
      user_tk: updatedWatchedStates.user_tk,
    };

    updateStates(payload).then((data) => {
      setWeeklyChoice(data);
      console.log('Updated ratings:', data);
    }).catch(error => console.error('Error updating ratings:', error));
  };

  const handleTeaseToggle = () => {
    setIsTeaseVisible(!isTeaseVisible);
  };

  return (
      <div className={styles.results}>
        <h1>Weekly Movie Choice</h1>
        {weeklyChoice ? (
            <div className={styles.movieDetails} onClick={handleTeaseToggle}>
              <h2>{weeklyChoice.movie.title}</h2>
              <img
                  src={`assets/posimages/${weeklyChoice.movie.id}.png`}
                  alt={weeklyChoice.movie.title}
              />
              <p>{weeklyChoice.movie.summary}</p>
              {isTeaseVisible && <div className={styles.teaseBubble}>{weeklyChoice.movie.tease}</div>}
              <div className={styles.watchedStatus}>
                {profiles.map(profile => (
                    <div key={profile.id}>
                      <label>
                        <span className={styles.userName}>{profile.name} rating:</span>
                        <div className={styles.rating}>
                          {emojis.map((emoji, index) => (
                              <span
                                  key={index}
                                  className={styles.emoji}
                                  onClick={() => handleRatingChange(profile.user_tag, index)}
                                  style={{
                                    transform: watchedStates[profile.user_tag] === index ? 'scale(1.5)' : 'scale(1)',
                                  }}
                              >
                                {emoji}
                              </span>
                          ))}
                        </div>
                      </label>
                    </div>
                ))}
              </div>
            </div>
        ) : (
            <p>Loading...</p>
        )}
      </div>
  );
};

export default Results;
