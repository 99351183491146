import { useEffect, useState } from 'react';

const useCurrentPage = () => {
    const determinePage = () => {
        const now = new Date();
        const israelTime = new Date(now.toLocaleString('en-US', { timeZone: 'Asia/Jerusalem' }));
        const day = israelTime.getDay();
        const hours = israelTime.getHours();

        console.log(`Current Israel Time: ${israelTime}`);
        console.log(`Day: ${day}, Hours: ${hours}`);
        console.log(`Condition: ${(day === 0 && hours >= 1) || (day === 1 && hours < 1)}`);

        // If it's Sunday between 1am and Monday 1am
        if ((day === 0 && hours >= 1) || (day === 1 && hours < 1)) {
            return '/voting';
        } else {
            return '/results';
        }
    };

    const [currentPage, setCurrentPage] = useState<string>(determinePage);

    useEffect(() => {
        const updatePage = () => {
            setCurrentPage(determinePage());
        };

        updatePage();
        const intervalId = setInterval(updatePage, 60000); // Update every minute

        return () => clearInterval(intervalId);
    }, []);

    return currentPage;
};

export default useCurrentPage;
