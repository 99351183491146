import React from 'react';
import classNames from 'classnames';
import styles from './ProfileCircle.module.css';

interface ProfileCircleProps {
  id: number;
  name: string;
  imageUrl?: string; // Make imageUrl optional if we're using id to construct the path
  onClick: () => void;
  isSelected: boolean;
  className?: string; // Add className prop
}

const ProfileCircle: React.FC<ProfileCircleProps> = ({ id, name, imageUrl, onClick, isSelected, className }) => {
  const imagePath = imageUrl ? `/assets/images/${imageUrl}` : `/assets/images/${id}.png`;

  return (
      <div
          className={classNames(styles.profileCircle, { [styles.selected]: isSelected }, className)}
          onClick={onClick}
      >
        <img src={imagePath} alt={name} className={styles.profileImage} />
      </div>
  );
};

export default ProfileCircle;
