import React, { createContext, useState,useContext, ReactNode } from 'react';

export interface Profile {
  id: number;
  name: string;
  imageUrl: string;
  user_tag: string;
}

interface ProfileContextType {
  profiles: Profile[];
  currentProfile: Profile | null;
  setCurrentProfile: (profile: Profile) => void;
}

const ProfileContext = createContext<ProfileContextType | undefined>(undefined);

const profilesData: Profile[] = [
  { id: 1, name: 'Danielle', imageUrl: 'daniel.png', user_tag: "user_dh" },
  { id: 2, name: 'David', imageUrl: 'david.png', user_tag: "user_ds" },
  { id: 3, name: 'Enny', imageUrl: 'enny.png', user_tag: "user_es" },
  { id: 4, name: 'Tal', imageUrl: 'tal.png', user_tag: "user_tk" },
];

export const ProfileProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [currentProfile, setCurrentProfile] = useState<Profile | null>(null);

  return (
      <ProfileContext.Provider value={{ profiles: profilesData, currentProfile, setCurrentProfile }}>
        {children}
      </ProfileContext.Provider>
  );
};

export const useProfile = () => {
  const context = useContext(ProfileContext);
  if (context === undefined) {
    throw new Error('useProfile must be used within a ProfileProvider');
  }
  return context;
};
