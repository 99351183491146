import React, { forwardRef } from 'react';
import styles from './Page.module.css';

interface PageProps {
  children: React.ReactNode;
}

const Page = forwardRef<HTMLDivElement, PageProps>(({ children }, ref) => {
  return (
    <div className={styles.page} ref={ref}>
      {children}
    </div>
  );
});

export default Page;
