import React, { useEffect, useState } from 'react';
import styles from './Clock.module.css';

const Clock: React.FC = () => {
    const [time, setTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);

    const israelTime = new Date(time.toLocaleString('en-US', { timeZone: 'Asia/Jerusalem' }));
    const dayOfWeek = israelTime.toLocaleString('en-US', { weekday: 'long' });
    const formattedTime = israelTime.toLocaleTimeString();

    return (
        <div className={styles.clockContainer}>
            <div className={styles.clockDay}>{dayOfWeek}</div>
            <div className={styles.clockTime}>{formattedTime}</div>
        </div>
    );
};

export default Clock;
