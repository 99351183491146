import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faTimes } from '@fortawesome/free-solid-svg-icons';
import Confetti from 'react-dom-confetti';
import styles from './Card.module.css';

interface CardProps {
  id: number;
  title: string;
  description: string;
  isFavorite: boolean;
  isVetoed: boolean;
  onFavorite: (id: number) => void;
  onVeto: (id: number) => void;
}

const Card: React.FC<CardProps> = ({ id, title, description, isFavorite, isVetoed, onFavorite, onVeto }) => {
  const [flipped, setFlipped] = useState(false);
  const [isZoomed, setIsZoomed] = useState(false);

  const handleFavoriteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onFavorite(id);
  };

  const handleVetoClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onVeto(id);
  };

  const toggleZoom = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsZoomed(!isZoomed);
  };

  return (
      <>
        <div
            className={`${styles.card} ${flipped ? styles.flipped : ''} ${isFavorite ? styles.favorite : ''} ${isVetoed ? styles.vetoed : ''}`}
            onClick={() => setFlipped(!flipped)}
        >
          <div className={styles.cardFront}>
            <h3 className={styles.cardTitle}>{title}</h3>
            <img
                src={`assets/posimages/${id}.png`}
                alt={title}
                className={styles.cardImage}
                onClick={toggleZoom}
            />
            <Confetti active={isFavorite} className={styles.confetti} />
          </div>
          <div className={styles.cardBack}>
            <p className={styles.cardDescription}>{description}</p>
            {flipped && (
                <div className={styles.symbols}>
              <span className={styles.symbol} onClick={handleFavoriteClick}>
                <FontAwesomeIcon icon={faStar} />
              </span>
                  <span className={styles.symbol} onClick={handleVetoClick}>
                <FontAwesomeIcon icon={faTimes} />
              </span>
                </div>
            )}
          </div>
        </div>
        {isZoomed && (
            <div className={styles.modal} onClick={toggleZoom}>
              <img src={`assets/posimages/${id}.png`} alt={title} className={styles.modalImage} />
            </div>
        )}
      </>
  );
};

export default Card;
