import React, { useState, useEffect } from 'react';
import styles from './Header.module.css';

interface HeaderProps {
    title: string;
}

const Header: React.FC<HeaderProps> = ({ title }) => {
    const images = Array.from({ length: 12 }, (_, i) => `/assets/hot_imgs/${i + 1}.png`);

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setVisible(false);
            setTimeout(() => {
                setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
                setVisible(true);
            }, 1500); // Time for fade-out transition
        }, 3500); // Total time between transitions

        return () => clearInterval(intervalId); // Clear interval on component unmount
    }, [images.length]);

    return (
        <header className={styles.header}>
            <div className={styles.imageContainer}>
                <img
                    src={images[currentImageIndex]}
                    alt="Header"
                    className={`${styles.headerImage} ${visible ? styles.visible : ''}`}
                />
            </div>
            <h1 className={styles.title}>{title}</h1>
        </header>
    );
};

export default Header;
