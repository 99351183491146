import { useContext } from 'react';
import { VotingContext } from '../context/VotingContext';
import { useProfile } from '../context/ProfileContext';
import { updateStates } from '../utils/api';

const useVoting = () => {
    const context = useContext(VotingContext);
    const { profiles } = useProfile();

    if (!context) {
        throw new Error('useVoting must be used within a VotingProvider');
    }

    const postVote = async (profileId: number, movieId: number, voteType: number) => {
        try {
            const profile = profiles.find(profile => profile.id === profileId);
            if (!profile) {
                throw new Error('Profile not found');
            }
            const stateUpdate = {
                movie_id: movieId,
                [profile.user_tag]: voteType
            };
            const response = await updateStates(stateUpdate);
            console.log('API Response:', response);
        } catch (error) {
            console.error('Failed to update states:', error);
        }
    };

    return { ...context, postVote };
};

export default useVoting;
