import React, { createContext, useState, ReactNode, useEffect } from 'react';
import { updateStates } from "../utils/api";

interface Vote {
    favorite: number | null;
    veto: number | null;
}

interface VotingContextType {
    votes: { [profileId: number]: Vote };
    setVote: (profileId: number, favorite: number | null, veto: number | null) => void;
    getVote: (profileId: number) => Vote;
    postVote: (profileId: number, movieId: number, voteType: number) => Promise<void>;
    initializeVotes: (initialVotes: { [profileId: number]: Vote }) => void;
}

const VotingContext = createContext<VotingContextType | undefined>(undefined);

const VotingProvider: React.FC<{ children: ReactNode, initialVotes?: { [profileId: number]: Vote } }> = ({ children, initialVotes = {} }) => {
    const [votes, setVotes] = useState<{ [profileId: number]: Vote }>({});

    useEffect(() => {
        if (initialVotes) {
            setVotes(initialVotes);
        }
    }, [initialVotes]);

    const setVote = (profileId: number, favorite: number | null, veto: number | null) => {
        setVotes(prevVotes => ({
            ...prevVotes,
            [profileId]: { favorite, veto }
        }));
    };

    const getVote = (profileId: number): Vote => {
        return votes[profileId] || { favorite: null, veto: null };
    };

    const postVote = async (profileId: number, movieId: number, voteType: number) => {
        try {
            const stateUpdate = {
                movie_id: movieId,
                [`user_${profileId}`]: voteType
            };
            await updateStates(stateUpdate);
        } catch (error) {
            console.error('Failed to update states:', error);
        }
    };

    const initializeVotes = (initialVotes: { [profileId: number]: Vote }) => {
        setVotes(initialVotes);
    };

    return (
        <VotingContext.Provider value={{ votes, setVote, getVote, postVote, initializeVotes }}>
            {children}
        </VotingContext.Provider>
    );
};

export { VotingProvider, VotingContext };
