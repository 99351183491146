import React, { useState, useEffect, useRef } from 'react';
import ProfileSection from '../components/ProfileSection/ProfileSection';
import CardSection from '../components/CardSection/CardSection';
import Page from '../components/Page/Page';
import Timer from '../components/Timer/Timer';
import Header from '../components/Header/Header';
import { useProfile } from '../context/ProfileContext';
import useVoting from '../hooks/useVoting';
import { useWeekManager } from '../context/WeekManagerContext';
import { Movie } from '../types/interfaces'; // Ensure correct import
import styles from './Home.module.css';

interface HomeProps {
  movies: Movie[];
}

const Home: React.FC<HomeProps> = ({ movies }) => {
  const { profiles, setCurrentProfile } = useProfile();
  const { setVote, getVote, postVote } = useVoting();
  const { startInteraction, checkProfileLock, timerDuration, isTimerRunning, startTimer } = useWeekManager();
  const [favorite, setFavorite] = useState<number | null>(null);
  const [veto, setVeto] = useState<number | null>(null);
  const [timerExpired, setTimerExpired] = useState(false);
  const [activeProfileId, setActiveProfileId] = useState<number | null>(null);
  const [warning, setWarning] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const pagesRef = useRef<(HTMLDivElement | null)[]>([]);

  const handleProfileClick = (profileId: number) => {
    if (!checkProfileLock(profileId)) {
      const profile = profiles.find(p => p.id === profileId);
      if (profile) {
        setCurrentProfile(profile);
        setActiveProfileId(profileId);
        const vote = getVote(profileId);
        setFavorite(vote.favorite);
        setVeto(vote.veto);
        setWarning(null); // Clear any existing warning
        setCurrentPage(1); // Move to the first card page
      }
    }
  };

  const handleFavorite = async (id: number) => {
    if (activeProfileId === null) {
      showWarning('Please select a profile before making a choice.');
      return;
    }
    if (!checkProfileLock(activeProfileId)) {
      if (veto === id) setVeto(null); // Reset veto if same card is favorited
      const newFavorite = favorite === id ? null : id;
      setFavorite(newFavorite);
      setVote(activeProfileId, newFavorite, null); // Update the vote with favorite
      const voteType = newFavorite === null ? 0 : 1; // 0 for unmarked, 1 for favorite
      await postVote(activeProfileId, id, voteType); // Post the vote
      startInteraction(activeProfileId);
      if (!isTimerRunning) startTimer();
    }
  };

  const handleVeto = async (id: number) => {
    if (activeProfileId === null) {
      showWarning('Please select a profile before making a choice.');
      return;
    }
    if (!checkProfileLock(activeProfileId)) {
      if (favorite === id) setFavorite(null); // Reset favorite if same card is vetoed
      const newVeto = veto === id ? null : id;
      setVeto(newVeto);
      setVote(activeProfileId, null, newVeto); // Update the vote with veto
      const voteType = newVeto === null ? 0 : 2; // 0 for unmarked, 2 for veto
      await postVote(activeProfileId, id, voteType); // Post the vote
      startInteraction(activeProfileId);
      if (!isTimerRunning) startTimer();
    }
  };

  const showWarning = (message: string) => {
    setWarning(message);
    setTimeout(() => setWarning(null), 2000); // Hide warning after 2 seconds
  };

  useEffect(() => {
    if (timerExpired && activeProfileId) {
      // Lock the profile after timer expires
      startInteraction(activeProfileId);
    }
  }, [timerExpired, activeProfileId, startInteraction]);

  useEffect(() => {
    if (pagesRef.current[currentPage]) {
      pagesRef.current[currentPage]!.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentPage]);

  const renderPage = (pageIndex: number) => {
    if (pageIndex === 0) {
      return (
          <Page key={pageIndex} ref={(el) => (pagesRef.current[pageIndex] = el)}>
            <Header title="Get Excited" />
            <ProfileSection
                profiles={profiles}
                handleProfileClick={handleProfileClick}
                activeProfileId={activeProfileId} // Pass activeProfileId
            />
          </Page>
      );
    }
    if (pageIndex > 0 && pageIndex <= movies.length) {
      return (
          <Page key={pageIndex} ref={(el) => (pagesRef.current[pageIndex] = el)}>
            <CardSection
                movie={movies[pageIndex - 1]}
                isFavorite={movies[pageIndex - 1].id === favorite}
                isVetoed={movies[pageIndex - 1].id === veto}
                handleFavorite={handleFavorite}
                handleVeto={handleVeto}
            />
          </Page>
      );
    }
    return null;
  };

  return (
      <div className={styles.home}>
        {Array.from({ length: movies.length + 1 }).map((_, index) => renderPage(index))}
        {isTimerRunning && (
            <div className={styles.timerContainer}>
              <div className={styles.timerTitle}>Time left: {timerDuration} minutes</div>
              <Timer duration={timerDuration * 60} onTimeUp={() => setTimerExpired(true)} />
            </div>
        )}
        {warning && <div className={`${styles.warning} ${styles.show}`}>{warning}</div>}
      </div>
  );
};

export default Home;
