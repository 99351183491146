import React from 'react';
import { TailSpin } from 'react-loader-spinner';
import styles from './SandClock.module.css';

const SandClock: React.FC = () => {
    return (
        <div className={styles.sandClockContainer}>
            <TailSpin color="#00BFFF" height={50} width={50} />
        </div>
    );
};

export default SandClock;
